import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "ninua_first_goal" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Полузащитник ФК ПАОК Николоз Нинуа забил свой первый официальный гол в составе своего нового клуба. Ника вышел на замену на 82 минуте в матче Греческой Супер Лиги против ФК Панетоликос и уже на 88 минуте открыл счёт своим голам прекрасным ударом со штрафного. Игра закончилась со счётом 3:1 в пользу ФК ПАОК. Поздравляем Николоза Нинуа и ждём продолжения!</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/S1oY9sVIq0Q"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}